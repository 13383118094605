import Block from '@blocks/index'
import Layout from '@components/Layout'
import Seo from '@components/Seo'
import { queries } from '@data/index'
import { useAppContext } from '@hooks/useAppContext'
import { client } from '@lib/sanity.client'
import { usePageType } from '@state/store'
import Error from 'next/error'
import { groq } from 'next-sanity'
import { PreviewSuspense } from 'next-sanity/preview'
import { lazy, useEffect } from 'react'

const PreviewHome = lazy(() => import('../preview/Home'))
const query = groq`*[_type == "pageSettings"][0].homePage->{
    _id,
    title,
    seoContent,
    pageBuilder[]{
      ${queries.builderQueries}
    }
  }`

export default function Home({ preview, data }) {
  const pageType = usePageType()
  const { globals } = useAppContext()
  const site = globals.site
  const { title, seoContent, pageBuilder } = data || {}
  const { seoTitle, seoDescription, seoImage } = seoContent || {}

  // console.log('Preview Mode: ', preview)

  useEffect(() => {
    pageType.setPageType('home')
  }, [pageType])

  if (!preview && !data) {
    return (
      <Error
        title={`"Home Page" is not set in Sanity, or the page data is missing`}
        statusCode="Data Error"
      />
    )
  }

  return preview ? (
    <PreviewSuspense fallback={<div>Loading...</div>}>
      <PreviewHome query={query} />
    </PreviewSuspense>
  ) : (
    <>
      <Seo
        title={seoTitle ? seoTitle : title}
        description={seoDescription ? seoDescription : null}
        image={seoImage ? seoImage : null}
        site={site}
      />
      <Layout>
        {pageBuilder?.map((block, key) => (
          <Block key={key} index={key} block={block} site={site} />
        ))}
      </Layout>
    </>
  )
}

export const getStaticProps = async ({ preview = false }) => {
  if (preview) {
    return { props: { preview } }
  }

  const data = await client.fetch(query)

  return { props: { preview, data } }
}
